import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ButtonModule } from '@teamfoster/sdk/button';
import { FromDictionaryPipe } from '@teamfoster/sdk/dictionary-ngrx';
import { IconComponent } from '@teamfoster/sdk/icon';

@Component({
  selector: 'app-team-cta',
  templateUrl: './team-cta.component.html',
  standalone: true,
  styleUrls: ['./team-cta.component.scss'],
  imports: [FromDictionaryPipe, RouterModule, IconComponent, ButtonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamCtaComponent {}
