import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromReviews from '../reducers/reviews.reducer';
import { Review } from '../../models';
import * as fromRoot from '../../../store';

export const getReviewState = createSelector(fromFeature.getPlacesState, (state: fromFeature.PlacesState) => state.reviews);

export const getReviews = createSelector(getReviewState, fromReviews.getReviews);
export const getReviewsRating = createSelector(getReviewState, fromReviews.getReviewsRating);
export const getReviewsCount = createSelector(getReviewState, fromReviews.getReviewsCount);
export const getReviewsLoaded = createSelector(getReviewState, fromReviews.getReviewsLoaded);
export const getReviewsLoading = createSelector(getReviewState, fromReviews.getReviewsLoading);
