import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { provideState } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';

import { reducers, effects, featureKey } from './store';
import { ReviewsService } from './services';

export function ProvidePlacesNgrx(): EnvironmentProviders {
  // using default values for missing properties
  return makeEnvironmentProviders([provideState(featureKey, reducers), provideEffects(effects), ReviewsService]);
}
