import { Injectable, Optional, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Review } from '../models';

@Injectable()
export class ReviewsService {
  constructor(
    private http: HttpClient,
    @Optional()
    @Inject('BASE_URL')
    private BASE_URL: string
  ) {}

  getReviewData() {
    return this.http.get<{ rating: number; count: number; reviews: Review[] }>(`${this.BASE_URL}api/reviews`);
  }
}
