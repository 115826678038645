import * as fromPages from '../reducers/reviews.reducer';
import { ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';
import { environment } from '../../../../environments/environment';

export const featureKey = 'places';

export interface PlacesState {
  reviews: fromPages.ReviewsState;
}

export const reducers: ActionReducerMap<PlacesState> = {
  reviews: fromPages.reviewReducer,
};

export const getPlacesState = createFeatureSelector<PlacesState>(featureKey);

export const metaReducers: MetaReducer<PlacesState>[] = !environment.production ? [] : [];
