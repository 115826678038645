<section class="team-cta py-5 bg-dark text-light">
  <div class="container team-cta__inner">
    <figure class="team-cta__figure">
      <img class="team-cta__members" [src]="('footer-member-image-1' | fromDictionary) + '?width=400&height=400&format=jpg'" alt="member" />
      <img class="team-cta__members" [src]="('footer-member-image-2' | fromDictionary) + '?width=400&height=400&format=jpg'" alt="member" />
      <img class="team-cta__members" [src]="('footer-member-image-3' | fromDictionary) + '?width=400&height=400&format=jpg'" alt="member" />
      <img class="team-cta__members" [src]="('footer-member-image-4' | fromDictionary) + '?width=400&height=400&format=jpg'" alt="member" />
    </figure>
    <main class="team-cta__content">
      <div class="text-max-width-xs">
        <h4 class="text-primary">{{ 'footer-team-call-to-action-tekst' | fromDictionary }}</h4>

        <a [href]="'footer-team-call-to-action-kennismaken-link' | fromDictionary">
          <button class="button button--light button-link h-auto">
            <span>{{ 'footer-team-call-to-action-kennismaken-knoptekst' | fromDictionary }}</span>
            <fstr-icon anchor="icon-arrow-right"></fstr-icon>
          </button>
        </a>
      </div>
    </main>
    <a class="button button--light" [href]="'team-call-to-action-contact-link' | fromDictionary">
      <span>{{ 'footer-team-call-to-action-contact-knoptekst' | fromDictionary }}</span>
      <fstr-icon anchor="line-icon-chevron-right"></fstr-icon>
    </a>
    <!-- <div class="d-flex justify-content-around col-3">
      </div> -->
  </div>
</section>
