<nav>
  <ul class="follow-button-list d-flex flex-wrap gap-3">
    @for (item of channels(); track item) {
      <li class="follow-button-list__item">
        <a [href]="item.url" target="_blank" class="button button-link button--info">
          <span class="sr-only">{{ item.title }}</span>

          @if (item.imageUrl) {
            <img [src]="item.imageUrl" [alt]="item.title" />
          }

          @if (!item.imageUrl && item.icon) {
            <fstr-icon [anchor]="item.icon" [title]="item.title"></fstr-icon>
          }
        </a>
      </li>
    }
  </ul>
</nav>
