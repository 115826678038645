import { createReducer, on } from '@ngrx/store';
import { ToEntities } from '@teamfoster/sdk/array-utility';
import { Review } from '../../models';
import * as pageActions from '../actions/reviews.action';

export interface ReviewsState {
  reviews: Review[];
  rating: number;
  count: number;
  loaded: boolean;
  loading: boolean;
  error?: any;
}

export const initialState: ReviewsState = {
  reviews: [],
  rating: 0,
  count: 0,
  loaded: false,
  loading: false,
};

export const reviewReducer = createReducer(
  initialState,
  on(pageActions.LoadReviews, (state: ReviewsState) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(pageActions.LoadReviewsSuccess, (state: ReviewsState, { reviewData }) => {
    return {
      ...state,
      reviews: reviewData.reviews,
      count: reviewData.count,
      rating: reviewData.rating,
      loading: false,
      loaded: true,
    };
  }),
  on(pageActions.LoadReviewsFail, (state: ReviewsState, { error }) => {
    return {
      ...state,
      loading: false,
      loaded: false,
      error,
    };
  })
);

export const getReviews = (state: ReviewsState) => state.reviews;
export const getReviewsRating = (state: ReviewsState) => state.rating;
export const getReviewsCount = (state: ReviewsState) => state.count;
export const getReviewsLoading = (state: ReviewsState) => state.loading;
export const getReviewsLoaded = (state: ReviewsState) => state.loaded;
