import { Routes } from '@angular/router';
import { CookieSettingsGuard } from '@teamfoster/sdk/cookie-ngrx';
import { DictionaryGuard } from '@teamfoster/sdk/dictionary-ngrx';
import { MenuGuard } from '@teamfoster/sdk/menu-ngrx';
import { NotFoundComponent } from './404.component';
import { SocialChannelsGuard } from './social-media/guards';
import { PartnerGuard } from './partners';

const appGuards = [CookieSettingsGuard, DictionaryGuard, SocialChannelsGuard, PartnerGuard, MenuGuard];

export const routes: Routes = [
  {
    path: 'styleguide',
    loadChildren: () => import('./styleguide/styleguide.routes').then(m => m.routes),
    canActivate: [CookieSettingsGuard],
  },
  {
    path: 'contentblokken',
    pathMatch: 'prefix',
    loadChildren: () => import('./content-blocks/content-blocks.routes').then(m => m.routes),
    canActivate: [...appGuards],
  },
  {
    path: 'cases',
    pathMatch: 'prefix',
    loadChildren: () => import('./cases/cases.routes').then(m => m.routes),
    canActivate: [...appGuards],
  },
  {
    path: 'pagina',
    pathMatch: 'prefix',
    loadChildren: () => import('./content/content.routes').then(m => m.routes),
    canActivate: [...appGuards],
  },
  {
    path: '404',
    component: NotFoundComponent,
    canActivate: [...appGuards],
  },
  {
    path: '',
    pathMatch: 'prefix',
    loadChildren: () => import('./landing-pages/landing-pages.routes').then(m => m.routes),
    canActivate: [...appGuards],
  },
  {
    path: '**',
    redirectTo: '/404',
  },
];
