import { Component, Input, OnInit, input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { ShowCookieSettings } from '@teamfoster/sdk/cookie-ngrx';
import { FromDictionaryPipe, LanguageService } from '@teamfoster/sdk/dictionary-ngrx';
import { IconComponent } from '@teamfoster/sdk/icon';
import { MenuItem } from '@teamfoster/sdk/menu-ngrx';
import { ContentPage } from 'src/app/content/models';
import { JsonLdComponent } from 'src/app/seo/components/json-ld/json-ld.component';
import { SocialChannelButtonsComponent } from 'src/app/social-media/components/social-channel-buttons/social-channel-buttons.component';
import { SocialChannel } from 'src/app/social-media/models';
import { TeamCtaComponent } from '../team-cta/team-cta.component';
import { LinkComponent } from '@teamfoster/sdk/button';
import { JsonPipe } from '@angular/common';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  standalone: true,
  styleUrls: ['./footer.component.scss'],
  imports: [
    FromDictionaryPipe,
    JsonLdComponent,
    RouterModule,
    IconComponent,
    SocialChannelButtonsComponent,
    TeamCtaComponent,
    LinkComponent,
    JsonPipe,
  ],
})
export class FooterComponent implements OnInit {
  private dict = new FromDictionaryPipe(this.lang);

  domain = input<string>('');
  menu = input<MenuItem[]>([]);
  socials = input<SocialChannel[]>([]);
  schema = input<any>({
    '@context': 'http://schema.org',
    '@type': this.dict.transform('footer-json-ld-meta-type'),
    'name': this.dict.transform('meta-titel'),
    'url': this.domain,
    'logo': `${this.domain}/assets/gfx/logo.svg`,
    'contactPoint': {
      '@type': 'ContactPoint',
      'email': this.dict.transform('contact-email'),
      'contactType': 'customer service',
    },
    'sameAs': [],
  });

  year = new Date().getFullYear();

  constructor(
    private store: Store,
    private lang: LanguageService
  ) {}

  ngOnInit(): void {
    this.socials().forEach(s => {
      const socialLink = s; // to do config with social buttons
      this.schema().sameAs.push(socialLink);
    });
  }

  openCookie() {
    this.store.dispatch(ShowCookieSettings());
  }
}
