import { inject } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import * as actions from '../actions/reviews.action';
import { switchMap, map, catchError } from 'rxjs/operators';
import { ReviewsService } from '../../services';

export const LoadReviews$ = createEffect(
  (actions$ = inject(Actions), service = inject(ReviewsService)) => {
    return actions$.pipe(
      ofType(actions.LoadReviews),
      switchMap(() =>
        service.getReviewData().pipe(
          map(reviewData => actions.LoadReviewsSuccess({ reviewData })),
          catchError(error => of(actions.LoadReviewsFail({ error })))
        )
      )
    );
  },
  { functional: true }
);
