<footer class="footer">
  <app-team-cta></app-team-cta>

  <main class="bg-gray-100 py-7 text-secondary">
    <div class="container">
      <div class="row">
        <div class="col-md-8">
          <div class="footer__logo mb-0">
            <span class="d-none">{{ 'meta-titel' | fromDictionary }}</span>
            <a [routerLink]="'/'">
              <img width="782" height="203" src="/assets/gfx/logo-wuestman-liggend.svg" [alt]="'meta-titel' | fromDictionary" />
            </a>
          </div>
          <p class="mt-4" [innerHTML]="'footer-adres' | fromDictionary"></p>
          <ul class="footer-adress-links">
            <li>
              <a class="button button--info button-link" [href]="'tel:' + ('footer-telefoonnummer' | fromDictionary)">
                <fstr-icon anchor="line-icon-phone"></fstr-icon>
                <span>{{ 'footer-telefoonnummer' | fromDictionary }}</span>
              </a>
            </li>
            <li>
              <a class="button button--info button-link" [href]="'mailto:' + ('footer-emailadres' | fromDictionary)">
                <fstr-icon anchor="icon-mail"></fstr-icon>
                <span>{{ 'footer-emailadres' | fromDictionary }}</span>
              </a>
            </li>
          </ul>
        </div>
        <nav class="col-md-8">
          <h3 class="h5">Service en info</h3>
          <ul class="mt-4 footer-menu-links">
            @for (page of menu(); track page) {
              <li>
                <!-- <a class="button button--info button-link" [routerLink]="'/'">Linkje</a> -->
                <fstr-link
                  cssClasses="button button--info button-link"
                  [button]="{ text: page.title, url: page.url || '', routerLink: page.routerLink }"
                  [routerLinkActive]="'is--active'"
                  [routerLinkActiveOptions]="{ exact: page.url === '/' }"
                  [showText]="true"
                ></fstr-link>
              </li>
            }
          </ul>
        </nav>

        <nav class="col-md-8">
          <h3 class="h5">{{ 'footer-volg-ons-titel' | fromDictionary }}</h3>

          <app-social-channel-buttons class="my-4 d-block" [channels]="socials()"></app-social-channel-buttons>

          <a class="button button--light" [href]="'footer-nieuwsbrief-link' | fromDictionary" target="_blank">
            <span>{{ 'footer-nieuwsbrief-label' | fromDictionary }}</span>
            <fstr-icon anchor="line-icon-chevron-right"></fstr-icon>
          </a>
        </nav>
      </div>
    </div>
  </main>
  <div class="container">
    <ul class="footer__nav-list py-4 d-flex flex-wrap gap-3 fs-body-sm">
      <li class="footer__nav-list__item">{{ year }} &copy; {{ 'meta-titel-suffix' | fromDictionary }}</li>
      <li class="footer__nav-list__item">
        <button (click)="openCookie()" class="button-link">{{ 'cookie-settings' | fromDictionary }}</button>
      </li>
    </ul>
  </div>

  <app-json-ld [json]="schema()"></app-json-ld>
</footer>
